<script setup>
import { ref, onMounted, watch } from 'vue';
import { translate } from "../assets/translations.js";
import { useRoute } from 'vue-router';

const route = useRoute();

const text = ref('');
const toRotateEs = ["Desarrollo Web", "Desarrollo de Aplicaciones"];
const toRotateEn = ["Web Development", "App Development"];
const period = 2000;
let i = 0;
let isDeleting = false;
let delta = 300;

const languageSelected = ref(localStorage.getItem("lang") || "es");
const toRotate = ref(languageSelected.value === "es" ? toRotateEs : toRotateEn);

const tick = () => {
  const fullText = toRotate.value[i];
  if (isDeleting) {
    text.value = fullText.substring(0, text.value.length - 1);
  } else {
    text.value = fullText.substring(0, text.value.length + 1);
  }

  if (!isDeleting && text.value === fullText) {
    isDeleting = true;
    delta = 1000;
  } else if (isDeleting && text.value === '') {
    isDeleting = false;
    i = (i + 1) % toRotate.value.length;
    delta = 2000;
  }
};

onMounted(() => {
  let ticker = setInterval(() => {
    tick();
  }, delta);

  return () => clearInterval(ticker);
});

watch(() => languageSelected.value, (newLang) => {
  toRotate.value = newLang === "es" ? toRotateEs : toRotateEn;
  i = 0;
  text.value = '';
  isDeleting = false;
  delta = 300;
});

</script>

<template>
  <section class="home section" id="home">
    <div class="home__container container grid section__border">
      <div class="home__data grid">
        <h1 class="home__title txt-rotate">
          {{ translate("titulo").name }}<br>
          <span class="wrap">{{ text }}</span><br><br>
        </h1>

        <div class="home__blob grid">
          <div class="home__perfil">
            <img src="../../public/img/resicorp-black.png" alt="Resi Corporation" title="Resi Corporation Logo">
          </div>

          <img src="../../public/img/shape-wawes.svg" alt="" class="home__shape-wawes">
          <img src="../../public/img/shape-circle.svg" alt="" class="home__shape-circle">
        </div>

        <ul class="home__social">
          <li>
            <a href="https://www.instagram.com/resicorporation/" target="_blank" rel="noopener noreferrer" title="Instagram Resi Corporation" class="home__social-link" aria-label="Instagram Resi Corporation">
              <i class="ri-instagram-line"></i>
            </a>
          </li>

          <li>
            <a title="Linkedin Máximo" href="https://www.linkedin.com/in/m%C3%A1ximo-resi-050609259/" target="_blank" rel="noopener noreferrer" class="home__social-link">
              <i class="ri-linkedin-fill"></i>
            </a>
          </li>

          <li>
            <a title="Linkedin Martín" href="https://www.linkedin.com/in/martin-catalano-resi/" target="_blank" rel="noopener noreferrer" class="home__social-link">
              <i class="ri-linkedin-fill"></i>
            </a>
          </li>
        </ul>
      </div>

      <div class="home__info">
        <div>
          <h2 class="home__info-title">
            {{ translate("biografia")?.name }}
          </h2>

          <p class="home__info-description">
            {{ translate("biografia")?.content }}
          </p>
        </div>
        <div>
          <h2 class="home__info-title">
            {{ translate("contacto").name }}
          </h2>

          <p class="home__info-description">
            Buenos Aires, Argentina <br>
            resicorporation@gmail.com <br>
            +54 11 6880-3040 <br>
            +54 11 5159-9231
          </p>
        </div>
        <div>
          <h2 class="home__info-title">
            {{ translate("servicios").name }}
          </h2>

          <p class="home__info-description">
            {{ translate("servicios").content }} <br>
          </p>
        </div>
      </div>

      <div class="home__info">
        <div>
          <h2 class="home__info-title">
            {{ translate("proyectos").name }}
          </h2>

          <p class="home__info-description">
            {{ translate("proyectos").content }}
          </p>
        </div>
        <div>
          <h2 class="home__info-title">
            {{ translate("idiomas").name }}
          </h2>

          <p class="home__info-description">
            {{ translate("idiomas").content }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.home__perfil img {
  z-index: 0;
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  25%{
    transform: translateX(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
</style>
